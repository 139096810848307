import { Paper, Card, CardHeader, CardContent } from '@mui/material';
import React from 'react';

export function Bookings(props) {
    const{clientes,hour} = props
    return (
        // publicidad
        // <Paper sx={{width:'md', textAlign: 'center'}} elevation={10}>
        <Paper sx={{width:1, textAlign: 'center'}} elevation={10}>
            <Card>
                <CardHeader
                    title = {hour==="Open Box" ? hour : "Clase de " + hour} 
                    sx={{bgcolor:'#7da92f',color:'#ffffff'}}
                />
                <CardContent sx={{textAlign: 'left'}}>
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                clientes.map(
                                    (cliente,index)=>(
                                        <tr key={ cliente.ClienteMembresiaId }>  
                                            <td> {(index+1)}</td>
                                            <td style={{ textTransform: 'uppercase'}}>{cliente.ClienteNombres+' '+cliente.ClienteApellidos}</td>
                                        </tr> 
                                    )
                                )
                            }
                        </tbody>
                    </table>
                </CardContent>
            </Card>
        </Paper>
    )
}
