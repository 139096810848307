 
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; 
import AuthProvider from "./auth/AuthProvider";
import NotFound from "./components/404";
import Home from "./components/Home";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

function App() { 
    return (
        <div className="App">
         
            <AuthProvider>
                <Router>
                    <Switch>
                        <PublicRoute path = "/" exact component = { Login } />
                        <PrivateRoute path = "/inicio" exact component = { Home } />
                        <Route path = "*" component = { NotFound } />
                    </Switch>
                </Router>
            </AuthProvider> 
        </div>
    );
}

export default App;