import React, { useState, useEffect, useCallback } from "react"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useAuth from '../auth/useAuth'
import { Bookings } from "./Bookings"
import { useHistory } from "react-router-dom"
import { CircularProgress, Backdrop, Container, Box, Button, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material'
import { AccountCircle, Logout, ScheduleSend } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { styled } from '@mui/material/styles';

export default function Home(props) {
    /*const {
        pro = ''
    } = props;*/

    const [loadDataBool, setLoadDataBool] = useState(false)
    const [clientes, setClientes] = useState([])
    const [minutes, setMinutes] = useState(0);
    const [hour, setHour] = useState(0)
    const [identificacion, setIdentificacion] = useState('')
    const [loading, setLoading] = useState(false)

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    }

    //cambiar
    const url = 'https://api.pukiebox.com/index';
    // const url = 'http://localhost/api.pukiebox.com/index';
    //console.log("Renderzado Home")

    const history = useHistory()
    const { user, logout } = useAuth()

    const loadData = useCallback(
        async() => {
            var dataUser = {
                usuario: user.username,
                token: user.token
            };
            try {
                const response = await fetch(
                    url, {
                        method: 'POST',
                        body: JSON.stringify(dataUser),
                    }
                )
                const json = await response?.json()
                setLoadDataBool(true)
                //const s = json.status
                if (json?.status === "ok") {
                    setClientes(json['result'])
                    setHour((json.length === 0) ? hour : json['result'][0]?.CuentaHoraSesion)
                } else if (json?.status === "error") {
                    setClientes([])
                    Swal.fire({
                        title: 'Error',
                        text: json['result'],
                        allowOutsideClick: true,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    setClientes([])
                    Swal.fire({
                        title: 'Error',
                        text: 'ha ocurrido un error inesperado',
                        allowOutsideClick: true,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            } catch (e) {
                setLoadDataBool(true)
                console.error(e)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [minutes, hour]
    )

    const btnLogout = (e) => {
        e.preventDefault()
            //toast: true,
            //const MySwal = withReactContent(Swal)
        Swal.fire({
            title: '¿Cerrar sesion?',
            text: "Cerraras sesion",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Salir!'
        }).then((result) => {
            if (result.isConfirmed) {
                logout()
                history.push("/")
            }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const estimatedTime = () => {
        const day = new Date().getDay()
        const h = new Date().getHours()
        
        // HardCode
        // const day = 1
        // const h = 15
        // minutes = 20 
        // endHardCoe

        var tempHour = 0
        if (day>= 1 && day <= 5) {
            if (h === 9) {
                tempHour = '9 am'
            } else if (h === 20) {
                tempHour = '8 pm'
            } else if (h>= 4 && h <= 8) {
                tempHour = minutes> 30 ? (new Date().getHours() + 1) + ' am' : new Date().getHours() + ' am'
            } else if(h> 9 && h <= 11){
                tempHour = "Open Box"
            }else if (h > 15 && h <= 19) {
                tempHour = minutes> 30 ? ((new Date().getHours() - 12) + 1) + ' pm' :
                    (new Date().getHours() - 12) + ' pm'
            } else if(h >= 14 && h <= 15){
                tempHour = "Open Box"
                if(h === 15){
                    tempHour = minutes> 30 ? ((new Date().getHours() - 12) + 1) + ' pm' : "Open Box"
                }
            }else {
                tempHour = 0
            }
        } else if (day === 6) {
            if (h === 9) {
                tempHour = '9 am'
            } else if (h>= 4 && h <= 8) {
                tempHour = minutes> 30 ? (new Date().getHours() + 1) + ' am' : new Date().getHours() + ' am'
            } else if(h> 9 && h <= 11){
                tempHour = "Open Box"
            }else{ tempHour = 0 }
        } else if (day === 0) {
            if (h === 9) {
                tempHour = '9 am'
            } else if (h>= 5 && h <= 8) {
                tempHour = minutes> 30 ? (new Date().getHours() + 1) + ' am' : new Date().getHours() + ' am'
            } else { tempHour = 0 }
        }
        return tempHour
        // return tempHour = "4 pm"
    }

    const handleInputChange = (event) => {
        setIdentificacion(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoading(true)

        const MySwal = withReactContent(Swal)   
    
        if (identificacion !== '') {

            if (hour === 0) {
                setIdentificacion('')
                setLoading(false)
            } else {

                var data2 = {
                    id: identificacion,
                    usuario: user.username,
                    token: user.token
                };

                setOpen(!open)
                fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(data2),
                    })
                    .then(response => response.json())
                    .then((json) => {
                        console.log(json) 
                        if (json?.result === "No autorizado") {
                            logout()
                            history.push("/")
                        } else {
                            setOpen(false)
                            setIdentificacion('')
                            if (json.status === "error") {
                                setLoading(false)
                                    /*const Toast = Swal.mixin({
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer)
                                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        }
                                        })*/
                                return MySwal.fire({
                                    timer: 3000,
                                    toast: true,
                                    width: '550px',
                                    timerProgressBar: true,
                                    title: <div> <p> { json.tittle } </p><hr/> </div>,
                                    html: <p> { json.message } <br />
                                        </p>,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    },
                                    icon: json.icon,
                                    confirmButtonColor: '#13a093',
                                    confirmButtonText: 'Continuar'
                                })
                            } else {
                                loadData()
                                setLoading(false)
                                if (json.plan === 'Personalizado' || json.plan === 'OpenBox') {
                                    let red = (json.deudaMembresia>0) ? "red" : "black";
                                    let red2 = (json.deudaProducto>0) ? "red" : "black";
                                    return MySwal.fire({
                                        timer: 6000,
                                        toast: true,
                                        width: '550px',
                                        timerProgressBar: true,
                                        title: <div> <p> { json.tittle } </p><hr /> </div>,
                                        html: <p> { json.message } <br /> <br />
                                            <b> Identificacion: </b> {json.id}<br/>
                                            <b> Nombre: </b> {json.client}<br/>
                                            <b> Plan: </b> {json.plan} <br/>
                                            <b> Dias Restantes: </b> {json.days} <br/>
                                            <b style={{color: red}}>   Deuda Membresia: {json.deudaMembresia} </b><br/>
                                            <b style={{color: red2}}>   Deuda Productos: {json.deudaProducto} </b><br/>
                                            </p>,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer)
                                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        },
                                        icon: json.icon,
                                        confirmButtonColor: '#13a093',
                                        confirmButtonText: 'Continuar'
                                    })
                                } else {
                                    let red = (json.deudaMembresia>0) ? "red" : "black";
                                    let red2 = (json.deudaProducto>0) ? "red" : "black";
                                    return MySwal.fire({
                                        timer: 6000,
                                        toast: true,
                                        width: '550px',
                                        timerProgressBar: true,
                                        title: <div> <p> { json.tittle }</p><hr /> </div>,
                                        html: <p> { json.message } <br /> <br />
                                            <b> Identificacion: </b> {json.id}<br/>
                                            <b> Nombre: </b> {json.client}<br/>
                                            <b> Plan: </b> {json.plan} <br/>
                                            <b> Creditos: </b> {json.credits} <br/>
                                            <b> Dias Restantes: </b> {json.days} <br/>
                                            <b style={{color: red}}>   Deuda Membresia: {json.deudaMembresia}</b><br/>
                                            <b style={{color: red2}}>   Deuda Productos: {json.deudaProducto} </b><br/>
                                            </p>,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer)
                                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        },
                                        icon: json.icon,
                                        confirmButtonColor: '#13a093',
                                        confirmButtonText: 'Continuar'
                                    })
                                }
                            }
                        }
                    })
                    .catch(() => {
                        setOpen(false)
                        console.error()
                        setLoading(false)
                        setIdentificacion('')
                        return MySwal.fire({
                            timer: 2000,
                            toast: true,
                            width: '550px',
                            timerProgressBar: true,
                            title: <div> <p> Error! </p><hr/> </div>,
                            html: <p> Ha ocurrido un error inesperado <br />
                                </p>,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                            icon: 'error',
                            confirmButtonColor: '#13a093',
                            confirmButtonText: 'Continuar'
                        })
                    });
            }
        } else {
            setLoading(false)
            return MySwal.fire({
                timer: 3000,
                toast: true,
                width: '550px',
                timerProgressBar: true,
                title: <p> Error <hr /> </p>,
                html: <p> Por favor ingrese su identificacion. </p>,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                },
                icon: 'error',
                confirmButtonText: 'Continuar'
            })
        }  
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setHour(estimatedTime)
        loadData()
        setMinutes(m => new Date().getMinutes())
            //console.log("1: "+hour)
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
            setHour(estimatedTime)
            const m = setTimeout(() => {
                setMinutes(m => new Date().getMinutes())
                if (hour === 0) {
                    return null
                } else {
                    loadData()
                }
                //console.log("2: "+hour)
            }, 60000)
            return () => clearInterval(m)
                // eslint-disable-next-line
        }, [minutes, hour])
        // eslint-disable-next-line
    useEffect(() => {
        setHour(estimatedTime)
        const firstLoad = setTimeout(() => {
            //console.log('before '+ minutes)
            setMinutes(m => new Date().getMinutes())
                //console.log("3: "+hour)   
        }, 300000)
        return () => clearInterval(firstLoad)
    })

    return ( <>
        { /*<Button onClick={handleToggle}>Show backdrop</Button>*/ } <
        Backdrop sx = {
            { color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }
        }
        open = { open }
        onClick = { handleClose }>
        <CircularProgress color = "inherit" />
        </Backdrop> 
        <Button variant = "outlined"
        color = "warning"
        sx = {
            { float: 'right' }
        }
        endIcon = { <Logout /> }
        onClick = { btnLogout }/> 
        <Container maxWidth = "lg">
            <Grid container spacing={2}>
                {/* publicidad */}
                {/* <Grid item>
                    <Sidebar backgroundColor="none" image="https://cazaofertas.com.co/wp-content/uploads/2019/03/bodytech-3-dias-gratis.jpg">
                                <Box sx={{
                                    width: 250,
                                    height: 750,
                                    marginTop: -50               
                                }}></Box>
                    </Sidebar>
                </Grid> */}
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid container justifyContent="center" alignItems="center" >
                            <Box sx = {{ textAlign: 'center', marginTop: 2 }}>
                                <img src = "logo192.png" height = "35%" width = "35%" alt = "pukiebox"/>
                                <h3> Registro </h3>  
                                <form onSubmit = { handleSubmit }>  
                                    <FormControl fullWidth sx = {{ mt: 2, mb: 1 }} variant = "outlined">
                                    <InputLabel htmlFor = "outlined-adornment-password"> Identificacion </InputLabel> 
                                    <OutlinedInput sx = {{ outlineColor: '#13a093' } }
                                    startAdornment = { 
                                        <InputAdornment position = "start">
                                        <AccountCircle/>
                                        </InputAdornment>
                                    }
                                    type = "tel"
                                    value = { identificacion }
                                    onChange = { handleInputChange }
                                    name = "id"
                                    id = "id"
                                    placeholder = "Ingrese su identificacion"
                                    autoComplete = "off"
                                    required label = "Identificacion" />
                                    </FormControl> 
                                    <LoadingButton fullWidth variant = "contained"
                                        color = "success"
                                        id = "btn-regist"
                                        type = "submit"
                                        sx = {
                                            {
                                                mt: 1,
                                                mb: 5,
                                                backgroundColor: '#13a093',
                                                '&:hover': {
                                                    backgroundColor: '#13a098',
                                                    opacity: [0.9, 0.8, 0.7]
                                                }
                                            }
                                        }
                                        endIcon = { <ScheduleSend /> }
                                        loading = { loading }
                                        loadingPosition = "end">
                                        Registrarme 
                                    </LoadingButton> 
                                </form> 
                            </Box> 
                        </Grid>
                        { hour !== 0 ? (loadDataBool === false ? ( <div> Cargando... </div>) : (<Bookings clientes={clientes} hour={hour} /> )) : <div> No hay mas clases </div> } 
                            
                         
                    </Grid>
                </Grid>
            </Grid>
        </Container> </>
        )
    }