import React, {useState} from "react"
import { useHistory } from "react-router-dom"
import useAuth from "../auth/useAuth"
import getAuth from "../helpers/getAuth"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CryptoJS from 'crypto-js'
import { Container,Box,Grid,Paper,FormControl,InputLabel,OutlinedInput,InputAdornment,IconButton, Backdrop, CircularProgress} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Visibility, VisibilityOff,AccountCircle} from '@mui/icons-material'
import LoginIcon from '@mui/icons-material/Login'

export default function Login() {
  const [user,setUser] = useState('')
  const [pass,setPass] = useState('')
  const [showPassword,setShowPassword] = useState(false)

  //state loading
  const [loading,setLoading] = useState(false)
  const {login} = useAuth()
  const history = useHistory()

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
      setOpen(false);
  }
  /*var dataUser = {
    usuario:'ED123',
    password:'Fl0rez_1067',
    token:'6b7f5f6c8d3e6b35fe53c61bb0a8c925'
  };*/

  const handleInputChange = (event) =>{
    const target = event.target
    //const value = target.type === 'checkbox' ? target.checked : target.value;
    //const name = target.name
    if(target.name==='user'){
            //var encryptedCP = CryptoJS.AES.encrypt(target.value, 'PUK13').toString()
            //console.log('Hereee!! '+encryptedCP)
            // var decryptedCP with CryptJS AES encryptedCP.toString()
            //var decryptedCP = CryptoJS.AES.decrypt(encryptedCP.toString(), 'PUK13').toString(CryptoJS.enc.Utf8)
            //console.log('Hereee!! '+decryptedCP)
        setUser(target.value)
    }else{
      setPass(target.value)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    getAuthLogin()
  }

  const getAuthLogin = () => {
    const MySwal = withReactContent(Swal)
    
    //var encryptedCP = CryptoJS.AES.encrypt(user, 'PUK13').toString()
    var dataUser = user
    var dataPass = pass
    var salt = CryptoJS.SHA256( "SaltPUK13" )
    var iv   = CryptoJS.SHA1( "VectorPUK13" )
    var key  = CryptoJS.SHA256( "KeyPUK13" )

    
    setOpen(!open)
    // Prepare substances
    salt = salt.toString( CryptoJS.enc.Base64 );
    iv   = iv.toString( CryptoJS.enc.Base64 ).substr( 0, 16 );
    iv   = CryptoJS.enc.Utf8.parse( iv );
    key  = key.toString( CryptoJS.enc.Base64 ).substr( 0, 32 );
    key  = CryptoJS.enc.Utf8.parse( key );

    // Cipher
    var encryptedUser = CryptoJS.AES.encrypt( salt + dataUser, key, { iv: iv })
    var encryptedPass = CryptoJS.AES.encrypt( salt + dataPass, key, { iv: iv })
    /*console.log('data!! '+encryptedCP.toString())
    console.log('salt!! '+salt)
    console.log('iv!! '+encryptedCP.iv.toString( CryptoJS.enc.Utf8 ))
    console.log('key!! '+encryptedCP.key.toString( CryptoJS.enc.Utf8 ))*/

    //var dec = CryptoJS.AES.decrypt( encryptedCP.toString(), key, { iv: iv }) 
    //console.log('valor original!! '+CryptoJS.enc.Utf8.stringify(dec))
    // var decryptedCP with CryptJS AES encryptedCP.toString()
    //console.log('antes ')
    getAuth(encryptedUser.toString(), encryptedPass.toString())

    getAuth(encryptedUser.toString(), encryptedPass.toString())
    .then((response) => {
      //console.log(response)
      //console.log(response.status)
        setOpen(false)
        if(response.status === 'error'){
          MySwal.fire({
              timer: 3500,
              icon: response.status,
              title: '<h5><b>Error al iniciar sesion</b><h5>',
              html: <p className="alignText">{response.result['error_msg']}</p>,
              showConfirmButton: true,
          })
          setLoading(false)
          setUser('')
          setPass('')
        }else{
          const data = {username: encryptedUser.toString(), token: response.result['token'] }
          //console.log(data)
          login(data)
          history.push("/inicio")
        }
    }).catch((err) => {
      console.error("Errorr!!: "+err) 
      setLoading(false)
    })
  }

  return (
    <Container maxWidth="sm">
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
       >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container direction="column"
        justifyContent="center"
        alignItems="center">
        <Paper elevation={6} sx={{mt:10}}>
          <Box sx={{ textAlign: 'center',mt:5 }}>
            <img src="logo192.png" alt="pukiebox"/>
            <h3>LOGIN CONTROL PUKIEBOX</h3>
          </Box>
          <Box sx={{p:2}}>
            <form onSubmit={handleSubmit}>
                  <FormControl fullWidth sx={{ mt: 1, mb:1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Usuario</InputLabel>
                    <OutlinedInput 
                      sx={{outlineColor:'#13a093'}}
                      startAdornment={ 
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment> 
                      }
                      type="text" 
                      id="user" 
                      name="user"
                      value={user} 
                      onChange={handleInputChange}
                      placeholder="Ingrese su usuario"
                      autoComplete="on"
                      required
                      label="Usuario"
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 1, mb:1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                    <OutlinedInput 
                      type={showPassword ? 'text' : 'password'}
                      value={pass}
                      onChange={handleInputChange}
                      id="pass"
                      name="pass"
                      placeholder="Ingrese su contraseña"
                      autoComplete="off"
                      required
                      // onChange={handleChange('password')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>setShowPassword(true)}
                            onMouseDown={()=>setShowPassword(false)}
                            edge="end"
                         >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Contraseña"
                    />
                  </FormControl>
                <LoadingButton fullWidth variant="contained"
                    sx={{ mt: 1, mb:1,
                      backgroundColor: '#13a093',
                      '&:hover': {
                        backgroundColor: '#13a098',
                        opacity: [0.9, 0.8, 0.7]
                        }
                    }}
                    // onClick={()=> setLoading(true)}
                    endIcon={<LoginIcon />} 
                    loading={loading}
                    loadingPosition="end"
                    type="submit"
                 >
                    Entrar
                  </LoadingButton>
            </form>
          </Box>
        </Paper>
      </Grid>
    </Container>
  )
}