const getAuth = async (user,pass) => {
    // Hard-code
    var url = 'https://api.pukiebox.com/auth';
    // var url = 'http://localhost/api.pukiebox.com/auth';
    var dataUser = {
        usuario: user,
        password: pass
    }
    //fectch API to get token 
    /*try{
        fetch(url,{ 
            method: 'POST',
            body: JSON.stringify(dataUser)
        })
        .then(response => response.json())
        .then(data => {return data})
        .catch((e)=>{console.error(e)})
    }catch(e){
        console.error(e)
    }*/

    
    try{
        const res = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataUser),
        });
        const au = await res.json();
        return au;
    }catch(e){
        console.error(e)
    }
}

export default getAuth;