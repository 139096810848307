import {Route,Redirect} from 'react-router-dom'
import useAuth from '../auth/useAuth'
//const user = 'algo'
export default function PublicRoute({component: Component, ...rest}) {
    const {isLogged} = useAuth()
    return (
        <Route {...rest}>
            {  !isLogged() ? <Component /> : <Redirect to="/inicio" /> }
        </Route>
    )
}
