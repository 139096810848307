import { createContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2' 
export const AuthContext = createContext()


const AuthProvider = ({children}) => {
    const [user,setUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
        //null
    )

    useEffect(()=>{
        try {
            localStorage.setItem("user",JSON.stringify(user))
        } catch (error) {
            localStorage.removeItem("user")
            console.log(error)
        }
    },[user])

    const contextValue = {
        user,
        async login(data){
            setUser({username: data.username, token: data.token})
        },
        async logout(){
            // Cambiar
            var url = 'https://api.pukiebox.com/auth';
            // var url = 'http://localhost/api.pukiebox.com/auth';
            var dataUser = {
                usuario: user.username
            };
            try{
                const response = await fetch(
                    url,
                    {
                        method: 'DELETE',
                        body: JSON.stringify(dataUser),
                    }
                )
                const json = await response?.json()   
                if(json.status==="ok"){
                    setUser(null)
                    localStorage.removeItem("user")
                }else{  
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al cerrar sesion',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }catch(e){ 
                console.error(e)
            }
        },
        isLogged(){
            return !!user
        }
    }
    return <AuthContext.Provider value={contextValue}>
        {children}
    </AuthContext.Provider>
}

export default AuthProvider
